import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { ProfileModel } from '@/models/profile-model'
import { walletStore } from '@/stores/wallet-store'
import { computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class CommunityOwnerBoardController {
  @computed get userProfile() {
    return walletStore.userProfile
  }

  @computed get avatar() {
    return this.userProfile?.avatar.url
  }

  @computed get username() {
    return this.userProfile?.username
  }

  @computed get totalFollowings() {
    return this.userProfile?.totalFollowings
  }

  @computed get totalFollowers() {
    return this.userProfile?.totalFollowers
  }

  @computed get totalPosts() {
    return this.userProfile?.totalPosts
  }
}

export const communityOwnerBoardController = new CommunityOwnerBoardController()
