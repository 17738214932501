






















































import Avatar from '@/modules/community/common/components/avatar.vue'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { communityOwnerBoardController } from './community-owner-board'
@Observer
@Component({
  components: {
    Avatar,
  },
})
export default class CommunityOwnerBoad extends Vue {
  controller = communityOwnerBoardController
  wallet = walletStore

  getUserAccountName() {
    return this.wallet.userProfile?.unique_id
  }
}
